@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.home {
    max-width: 1920px;
    margin: auto;

    &.banner {
        .swiper-slide {
            text-align: center;
        }
    }

    &.body {
        background-color: rgba(130, 0, 0, 0.8);
        border-bottom: 1px solid white;
        padding: 2rem 0;

        @include media-breakpoint-down(md) {
            text-align: center;
            padding: 2rem 0 0;
            border: none;
        }

        .container {
            letter-spacing: 0 !important;
            color: white;

            .row {
                .col-md-6 {
                    h2, h3 {
                        font-size: 1.125rem;
                        margin-bottom: 1rem;
                        font-weight: bold;
                    }

                    &:last-child {
                        text-align: center;

                        @include media-breakpoint-down(md) {
                            margin-top: 1rem;
                        }

                        .card {
                            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
                            background-color: white;
                            border-radius: 5px;
                            color: black;
                            padding: 1rem;

                            p {
                                font-weight: bold;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
